export function authHeader() {
    // return authorization header with jwt token
    let user = JSON.parse(localStorage.getItem('dataUser'));
    let token = JSON.parse(localStorage.getItem('dataToken'));
    
    if (user && token.access_token) {
        return { 'Authorization': 'Bearer ' + token.access_token,
                 'Content-Type': 'application/json',  
         };
    } else {
        this.$router.push({
            path: '/login'
        });
    }
}

export function validateWebToken(error){
    if(error.data.error=='Token has expired'){
      localStorage.clear();
      var URLactual = window.location.href;
      URLactual = URLactual.substring(0, URLactual.lastIndexOf('/'))
      URLactual = URLactual + '/logout';
      window.location.href=URLactual;
    }
  
  }