
export default [

  {
    path: "/Mod_NS_Configuration",
    name: "configuracion",
    meta: {
      title: "configuración",
      authRequired: true,
    },
    component: () => import("../views/pages/coming-soon.vue"),
  },  
  {  
    path: "/Mod_NS_Configuration/ns_ConRouter",
    name: "GestionRutas",
    meta: {
      title: "Rutas",
      requiresAuth: true,
    },
    component: () => import( "@/views/configuration/routes/index.vue" ),
  },

  {
    path: "/test",
    name: "test",
    meta: {
      title: "test",
      authRequired: true,
    },
    component: () => import("../views/pages/test.vue"),
  },

  
];